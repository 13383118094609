import "./App.css";
import * as React from "react";
import { Box, Link, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

export default function App() {
  return (
    <Box sx={{ width: "100%", bgcolor: "#FEFDFE" }}>
      <Box height={66}>
        <Grid container spacing={2} height={"100%"}>
          <Grid item xs={2} height={"100%"}>
            <img
              style={{ height: 50 }}
              alt="Commonwealth Eye Center"
              src="/images/logo.jpeg"
            />
          </Grid>
          <Grid item xs={8} height={"100%"}></Grid>
          <Grid item xs={2} height={"100%"}></Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#F4F4F4", paddingBottom: 5 }}>
        <Box align={"center"} fontSize={40}>
          Commonwealth Eye Center
        </Box>
        <br />
        <Box align={"center"} fontSize={16}>
          Call us at{" "}
          <Link color="#6386D6" href="tel:540-825-3655">
            (540) 825 - 3655
          </Link>{" "}
          to book an{" "}
          <span data-toggle="tooltip" title="I toad a so!">
            appointment!
          </span>
        </Box>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#FEFDFE", marginBottom: 10 }}>
        <Box align={"center"}>
          <img
            width={"100%"}
            style={{
              maxHeight: "500px",
              objectFit: "cover",
              objectPosition: "50% 50%",
            }}
            alt="Front of office outside"
            src="/images/cec.jpeg"
          />
        </Box>
        <Grid container spacing={2} height={"50%"}>
          <Grid item xs={2} height={"100%"}></Grid>
          <Grid item xs={8} height={"100%"}>
            <Box
              boxShadow={15}
              align={"center"}
              sx={{ marginBottom: 10, marginTop: 5 }}>
              <Card height="100%" sx={{ bgcolor: "#6386D6" }}>
                <CardContent height="100%">
                  <Typography
                    sx={{ fontSize: 25, color: "#FFFFFF" }}
                    gutterBottom>
                    Community
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: "#FFFFFF" }}
                    gutterBottom>
                    Community is a cornerstone of our practice. From our staff
                    to Dr. Brear, we believe our patients should be in the best
                    hands possible and seek to provide an atmosphere of comfort
                    and familiarity.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            <Box boxShadow={15} align={"center"} sx={{ marginBottom: 10 }}>
              <Card height="100%" sx={{ bgcolor: "#6386D6" }}>
                <CardContent height="100%">
                  <Typography
                    height="100%"
                    sx={{ fontSize: 25, color: "#FFFFFF" }}
                    gutterBottom>
                    Wellness
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: "#FFFFFF" }}
                    gutterBottom>
                    Your care is of the utmost importance to us. From the moment
                    you enter, we pride ourselves on first-rate care regardless
                    of whether you're visiting for a checkup or an emergency.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            <Box boxShadow={15} align={"center"} sx={{ marginBottom: 5 }}>
              <Card height="100%" sx={{ bgcolor: "#6386D6" }}>
                <CardContent height="100%">
                  <Typography
                    height="100%"
                    sx={{ fontSize: 25, color: "#FFFFFF" }}
                    gutterBottom>
                    Ease of Mind
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: "#FFFFFF" }}
                    gutterBottom>
                    At Commonwealth Eye Center, we wish to provide as much care
                    and assistance as possible. We aim to provide you with
                    simple, ease of mind care, and to inform you of all options.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={2} height={"100%"}></Grid>
        </Grid>
        <Grid container spacing={2} height={"15%"} align={"center"}>
          <Grid item xs={1} height={"100%"}></Grid>
          <Grid item xs={10} height={"100%"}>
            <Box align={"center"} width={"100%"}>
              <iframe
                title="how to find us"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6248.690250759841!2d-78.01556012403452!3d38.4565928725929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b426acec559dbd%3A0xc258f840c68aad88!2sCommonwealth%20Eye%20Center!5e0!3m2!1sen!2sus!4v1714011141050!5m2!1sen!2sus"
                width={350}
                height={225}
                border={1}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Box>
          </Grid>
          <Grid item xs={1} height={"100%"}></Grid>
        </Grid>
        <Box align={"center"}>
          <Typography sx={{ fontSize: 25 }} gutterBottom>
            Hours of Operation:
          </Typography>
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            <b>Monday</b>: 8:00 AM - 4:00 PM
          </Typography>
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            <b>Tuesday</b>: 8:00 AM - 4:00 PM
          </Typography>
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            <b>Wednesday</b>: 8:00 AM - 4:00 PM
          </Typography>
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            <b>Thursday</b>: 8:00 AM - 4:00 PM
          </Typography>
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            <b>Friday</b>: 8:00 AM - 12:00 PM
          </Typography>
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            <b>Saturday</b>: Closed
          </Typography>
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            <b>Sunday</b>: Closed
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#6386D6" }}>
        <Grid container spacing={2} height={"100%"}>
          <Grid item xs={6} height={"100%"}>
            <Box align={"center"}>
              <br />
              <Typography sx={{ fontSize: 20, color: "#FFFFFF" }} gutterBottom>
                Contact
              </Typography>
              <br />
              <Typography sx={{ fontSize: 16, color: "#FFFFFF" }} gutterBottom>
                Phone:{" "}
                <Link color="#FFFFFF" href="tel:540-825-3655">
                  (540) 825 - 3655
                </Link>
              </Typography>
              <br />
              <Typography sx={{ fontSize: 16, color: "#FFFFFF" }} gutterBottom>
                Fax: (540) 825 - 5574
              </Typography>
              <br />
              <Typography sx={{ fontSize: 16, color: "#FFFFFF" }} gutterBottom>
                633 Sunset Ln. STE. E, Culpeper, VA 22701
              </Typography>
              <br />
              <br />
            </Box>
          </Grid>
          <Grid item xs={1} height={"100%"}>
            <Box align={"center"} width={100}></Box>
          </Grid>
          <Grid item xs={5} height={"100%"}>
            <Box align={"center"} width={100}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Typography sx={{ fontSize: 16, color: "#FFFFFF" }} gutterBottom>
                Check us out on{" "}
                <Link
                  color={"#FFFFFF"}
                  href="https://www.facebook.com/CommonwealthEyeCenter/">
                  Facebook
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#FEFDFE" }}>
        <Box>
          <Typography sx={{ fontSize: 14 }} gutterBottom>
            Copyright 2024 Commonwealth Eye Center
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
